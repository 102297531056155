import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Card} from "react-bootstrap";
import PricingButton from "./components/PricingButton";
import {Helmet} from "react-helmet";
import {useAuth} from "./AuthUser";
import {SwiperInstructors} from "./components/SwiperInstructors";

function Pricing() {
	const { setUser, user } = useAuth();

	return (
		<>
			<Helmet>
				<title>Choose Your Instructor | MyGolfDNA.com</title>
				<meta name="description" content="Choose Your Coach"/>
			</Helmet>
			<div className="bg-dark-blue" id="instructors" style={{

			}}>

				<div className="p-4">
					<h1 className="text-center monument-font-black text-white mb-3">Choose Your Coach</h1>
					<p className="text-orange text-center monument-font">Get started today by selecting your coach below</p>
					<SwiperInstructors/>
				</div>

			</div>
			</>
	)
}

export default Pricing;
